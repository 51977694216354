@use "sass:map";
@use "@angular/material" as mat;
@use "./nav-item-link/nav-item-link.component.theme" as nav-item-link;

/**
See https://material.angular.io/guide/theming-your-components
for theming docs
 */

@mixin style() {

}


@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, "primary");
  $accent-palette: map.get($color-config, "accent");
  $warn-palette: map.get($color-config, "warn");
  $is-dark-theme: map.get($color-config, "is-dark");


  .ng-pat-sidenav-menu {

    .ng-pat-sidenav-menu-header {
      border-bottom: 1px solid rgba(0, 0, 0, .12);
    }


    .navbar-toggle {
      border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
  }

  @if ($is-dark-theme) {


    .ng-pat-sidenav-menu {

      .section-header {
        color: rgba(255, 255, 255, 0.5);
      }


      .ng-pat-sidenav-menu-header, .home-container {
        border-bottom: 1px solid rgba(255, 255, 255, .12);

        .ng-pat-nav-item-link a.active .link-title {
          color: white;
        }
      }

      .navbar-toggle {
        background-color: rgba(0,0,0,0);
        border-top: 1px solid rgba(255, 255, 255, 0.12);
      }

      .mat-expansion-panel {
        background-color: rgba(71,98,130,0.2);

        &:hover {
          background-color: rgba(71,98,130,0.4);
        }
      }

    }
  }


  //.my-carousel-button {
  //  // Read the 500 hue from the primary color palette.
  //  color: mat.get-color-from-palette($primary-palette, 500);
  //}
}

@mixin typography($theme) {
  // Get the typography config from the theme.
  $typography-config: mat.get-typography-config($theme);

  //.my-carousel {
  //  font-family: mat.font-family($typography-config);
  //}
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
    @include nav-item-link.color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
    @include nav-item-link.typography($theme);
  }
}
