@use "@angular/material" as mat;
@use "../../../../libs/shared/ng-pat/material" as ngpat;

@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
@include mat.core();

$theme: mat.define-theme(
  (
    color: (
      theme-type: dark,
      primary: mat.$blue-palette
    )
  )
);

html {
  @include mat.all-component-themes($theme);
}

body {
  //  BEGIN MATERIAL OVERRIDES
  //--mdc-outlined-text-field-container-shape: var(--dlc-form-field-border-radius, 0.5rem);

  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline
    .mdc-notched-outline__notch {
    border-right: 0;
  }

  .mat-icon.material-symbols-outlined {
    font-variation-settings:
      "FILL" 0,
      "wght" 400,
      "GRAD" 0,
      "opsz" 24;
  }

  background-color: #000000;

  // END MATERIAL OVERRIDES
}

//$bg-dark-primary: mat.m2-define-palette(ngpat.$tw-grey-palette, 400, 300, 600);
//$my-primary: mat.m2-define-palette(ngpat.$tw-indigo-palette, A200, A100, A400);
//$my-accent: mat.m2-define-palette(ngpat.$tw-pink-palette, 400);
//// The "warn" palette is optional and defaults to red if not specified.
//$my-warn: mat.m2-define-palette(ngpat.$tw-red-palette);
//$typography-config: mat.m2-define-typography-config();
//$theme: mat.m2-define-dark-theme(
//  (
//    color: (
//      primary: $my-primary,
//      accent: $my-accent,
//      warn: $my-warn
//    ),
//    typography: $typography-config,
//    density: 0
//  )
//);
//
//$theme: ngpat.override-dark-theme-background-with-lightness-saturation(
//  $theme,
//  //map-get($default-dark-theme, primary),
//  $bg-dark-primary,
//
//  // $lightness
//  0%,
//  // $saturation
//  -6%
//);
//
//@include mat.all-component-typographies($typography-config);
//@include mat.all-component-themes($theme);
//
//.mat-mdc-form-field.mat-focused {
//  .mat-mdc-text-field-wrapper.mdc-text-field--focused {
//    .mdc-notched-outline .mdc-notched-outline__notch {
//      border-right-width: 0;
//    }
//  }
//}

html {
  font-size: 16px;
}

html,
body {
  height: 100%;
  font-size: 16px;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-weight: 300;
}
